//./plugins/posthog.js
import posthog from 'posthog-js'

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      'phc_zs2SVJTtPJSfhfKBiVWR3zgIzbINSp5giinSW8vl5A4',
      {
        api_host: 'https://eu.i.posthog.com'
      }
    )
  }
}
