<template>
  <form @submit="onSubmit">
    <div class="input-wrapper" v-show="section === 'HOME'">
      <p class="lead-text" v-if="showName">Tell us about your organisation in a few words</p>
      <input
        type="text"
        name="summary"
        id="summary"
        v-model="summary"
        placeholder="Describe your customer and location"
        class="large bg-dark"
        @focus="inputFocused('clicked_try_it_yourself')"
      />
    </div>
    <div class="more-questions" v-show="section !== 'HOME'">
      <div class="header">
        <h3>Answer a few more questions to tailor your results</h3>
      </div>
      <div class="questions">
        <div class="input-wrapper">
          <p class="lead-text">
            Tell me more about your target audience. Are there specific demographics or preferences you'd like to cater
            to?
          </p>
          <input
            placeholder="For example: the audience is health-conscious individuals"
            class="thin"
            type="text"
            name="audience"
            v-model="audience"
            @focus="inputFocused('clicked_describe_audience')"
          />
        </div>
        <div class="input-wrapper">
          <p class="lead-text">What makes your company stand out from the crowd?</p>
          <input
            placeholder="For example: we only work with local produce"
            class="thin"
            type="text"
            name="usp"
            v-model="usp"
            @focus="inputFocused('clicked_enter_usp')"
          />
        </div>
        <div class="buttons">
          <button :disabled="!usp || !audience">Submit</button>
          <button>Skip</button>
        </div>
      </div>
    </div>
    <button v-show="section === 'HOME'">Submit answer</button>
  </form>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useForm, useIsFormValid, useField } from 'vee-validate'
import { object, string } from 'yup'
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { getArtStyles } from '../../services/strapi-service'
import { useToastStore } from '@/stores/toast'
import posthog from 'posthog-js'

const { section } = defineProps({
  showName: { type: Boolean, default: false },
  section: {
    type: String,
    required: true,
    validator: (value: string) => ['HOME', 'AUDIENCE'].includes(value)
  }
})
const schema = object({
  summary: section === 'HOME' ? string().required() : string(),
  audience: string(),
  usp: string()
})

const { values, handleSubmit } = useForm({
  validationSchema: schema
})

const emit = defineEmits(['submitted'])
const { value: summary } = useField<string>(() => 'summary')
const { value: audience } = useField<string>(() => 'audience')
const { value: usp } = useField<string>(() => 'usp')

const authStore = useAuthStore()
const { isLoggedIn } = storeToRefs(authStore)
const { alert } = useToastStore()
const showModal = ref(false)
const isFormValid = useIsFormValid()
const artStyle = ref('')

const onSubmit = handleSubmit(() => {

  if (!isFormValid) {
    posthog.capture('create_project_submitted_failed', {
      isLoggedIn: isLoggedIn,
      section: section
    })
    alert('Please fill in all fields.', 'error')
    return
  }
  posthog.capture('create_project_submitted', {
    isLoggedIn: isLoggedIn,
    section: section
  })
  showModal.value = false
  emit('submitted', { ...values, artStyle: artStyle.value })
})

const inputFocused = (name: string) => {
  posthog.capture(name, {
    isLoggedIn: isLoggedIn,
    section: section
  })
}

onMounted(async () => {
  const art = await getArtStyles()

  const items = art.map((item) => {
    return {
      name: item.Name,
      url: item.Image.data[0].attributes.url
    }
  })

  artStyle.value = items[0].name
  if (isLoggedIn.value) {
    await authStore.getSubscription()
  }
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

form {
  text-align: center;

  button {
    width: 100%;
    @include sm {
      width: auto;
      margin-left: 0;
    }
  }

  .input-wrapper {
    text-align: left;
    margin-bottom: 2rem;

    input {
      margin: 0;
      background-color: $grey-bg;
    }

    #summary {
      background-color: $light-green;
    }

    p {
      margin-bottom: 1rem;
      padding-left: 1.875rem;
      font-weight: 600;
    }
  }

  .more-questions {
    background-color: $dark-grey;
    margin: 0 auto;
    width: fit-content;
    border-radius: 30px;
    overflow: hidden;
    max-width: 53.5rem;

    .header {
      padding: 3rem;
      background-color: $black;
      color: white;
      width: 100%;

      h3 {
        margin-bottom: 0;
      }
    }

    .questions {
      padding: 3rem;
      background-color: $dark-grey;
      @include sm {
        min-width: 40rem;
      }

      .buttons {
        @include sm {
          display: flex;
          gap: 2rem;
          margin-top: 1rem;
          align-items: center;
          justify-content: center;
        }

        button {
          &.text-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            background: transparent;
            color: $black;
            border: none;
            padding: 0;
            text-align: left;
            font-weight: 400;

            img {
              width: 0.5rem;
            }
          }

          .chevron {
            height: 1rem;
            width: 1rem;
            background-image: url('@assets/icons/chevron-right.svg');
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 12px 12px;
          }

          @include sm {
            width: fit-content;
            margin: 0;
          }

          &:hover {
            &.text-button {
              color: #0053b8;

              .chevron {
                background-image: url('@assets/icons/chevron-blue.svg');
              }
            }
          }
        }
      }
    }
  }
}
</style>
